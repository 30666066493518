.events{
    display: flex;
}
.events .main{
    width: 50%;
}
.events .main:first-child{
    text-align: right;
    padding: 50px 120px;
    background: url('../../public/images/test.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    align-content: center;
}
.events .main:first-child h2{
    position: relative;
    color: initial;
    z-index: 2;
}
.events .main:first-child::after{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
}
.events .main:first-child p{
    color: initial;
    position: relative;
    z-index: 2;
}
.events .main:first-child p{
    text-align: right;
}
.events .main:last-child{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
.events .main .child{
    width: 48%;
    flex-grow: 1;
    height: 250px;
    background: url("../../public/images/pd.jpg");
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.events .main .child:before{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, black);
}
.events .main:last-child .div{
    position: absolute;
    padding: 0 30px;
    bottom: 5px;
    width: 100%;
    transition: 1s;
}
.events .main .child:hover .div{
    bottom: 20px;
}
.events h2{
    font-size: 1.4rem;
}
.events h3{
    font-size: 1.2rem;
}
.events button{
    padding: 10px;
    font-size: 0.9rem;
    position: relative;
    z-index: 2;
}
@media only screen and (max-width: 998px){
    .events{
        display: block;
    }
    .events .main{
        width: 100%;
    }
    .events .main:first-child{
        text-align: center;
        padding: 20px 40px;
    }
    .events .main:first-child p{
        text-align: unset;
    }
    .events .main:last-child{
        display: block;
        flex-wrap: wrap;
        gap: 5px;
    }
    .events .main .child:first-child{
        margin-top: 20px;
    }
    .events .main .child{
        width:90%;
        margin: auto;
        flex-grow: 1;
        height: 250px;
        background: url("../../public/images/pd.jpg");
        background-size: cover;
        position: relative;
        overflow: hidden;
        border: 1px solid orange;
        border-radius: 20px 0;
        margin-bottom: 20px;
    }
}