.contact {
    padding: 2.5% 10%;
    width: 100%;
    background: url('../../public/images/magic.jpg');
    background-attachment: fixed;
    background-size: cover;
    position: relative;
}

.contact::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
}

.contact span {
    display: block;
    color: white;
    font-size: 0.875rem;
}

.contact form {
    width: 100%;
    position: relative;
    z-index: 1;
}

.contact .div {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}

.contact label {
    display: block;
    color: white;
    padding: 8px 0;
}

.contact .div input,
.contact textarea {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
}

.contact textarea {
    height: 120px;
}

.contact button {
    padding: 10px;
}

.contact .div input:focus,
.contact textarea:focus {
    outline: none;
    border: 2px solid orange;
    background: rgb(247, 246, 246);
}

@media only screen and (max-width: 998px) {
    .contact .div {
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(1, 1fr);
    }

    .contact label {
        display: block;
        color: white;
        padding: 5px 0;
    }

    .contact .div input,
    .contact textarea {
        width: 100%;
        padding: 7px;
    }

    .contact textarea {
        height: 120px;
    }

}