.team{
    padding: 2.5% 10%;
}
.team h2{
    color: orange;
}
.contact{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.contact .child{
    width: 32%;
}
.contact div img{
    width: 100%;
}
.contact .child div{
    background-color: white;
}
.contact .child div h3,
.contact .child div p{
    color: black;
}
.contact .child div h3{
    color: orange;
    font-size: 1.2rem;
    padding: 10px 0;
}
.contact .child div p{
    width: 90%;
    margin: auto;
    padding: 10px;
}