.services{
    padding: 2.5% 10%;
}
.services .divided{
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
}

.services .divided div{
    width: 30%;
    flex-grow: 1;
    text-align: center;
    border: 1px solid orange;
    padding: 20px;
}
.services .divided div a{
    display: block;
    font-size: 1.2rem;
    padding: 30px 0;
    transition: 1s;
    color: orange;
    text-decoration: none;
}
.services h2{
    text-align: center;
}
.services .one{
    text-align: center;
    width: 40%;
    margin: auto;
    padding: 20px 0;
}
.services .divided div a:hover{
    color: white;
}
.services p{
    padding: 30px 0;
}
.serviceInfo img{
    height: 600px;
}
@media only screen and (max-width: 998px){
    .services .one{
        text-align: center;
        width: 100%;
        margin: auto;
        padding: 20px 0;
    }
    .services .divided div{
        width: 100%;
        flex-grow: 1;
        text-align: center;
        border: 1px solid orange;
        padding: 20px;
        margin-bottom: 20px;
    }
    .serviceInfo div:first-child{
        margin-bottom: 20px;
    }
    .serviceInfo img{
        height: auto;
    }
}

