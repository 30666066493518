.how{
    background: url("../../public/images/bg.jpeg");
    background-size: cover;
    background-position: bottom;
    padding: 2.5% 10%;
}
.how h2, .how h3, .how p, .how a{
    color: initial;
}
.howItems{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}
.howItems .card{
    border-radius: 0;
    padding: 80px 40px;
    border: none;
    background: rgba(255, 255, 255, 0.5);
}
.howItems .card div{
    display: flex;
    gap: 30px;
    align-items: flex-end;
}
.how .card h2{
    color: rgb(7, 111, 111);
    font-size: 5rem;
}
.howItems .card h3{
    word-wrap: break-word;
}
.how p{
    padding: 20px 0;
}
.how h3{
    font-size: 1.6rem;
}
.how a{
    text-transform: uppercase;
    text-decoration: none;
    display: inline;
    border-bottom: 2px solid black;
    padding: 4px 0;
}
@media only screen and (max-width: 998px){
    .how{
        background: url("../../public/images/bg.jpeg");
        background-size: cover;
        background-position: center;
        padding: 1.5% 5%;
        padding-bottom: 50px;
    }
    .howItems{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
    }
    .howItems .card{
        border-radius: 0;
        padding: 40px 20px;
        border: 1px solid #ebebeb;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
    }
    .how h2{
        padding: 20px 0;
        text-align: center;
        font-size: 1.5rem;
    }
    .how p{
        padding: 10px 0;
    }
    .how .card h2{
        color: rgb(7, 111, 111);
        font-size: 2rem;
        padding: initial;
        text-align: initial;
    }
    .how h3{
        font-size: 1.3rem;
    }
}