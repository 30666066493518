p, h2, h3, h4, h5, h6, a, em{
    color: white;
}
button{
    padding: 15px;
    background-color: #e3ae4b;
    color: white;
    font-weight: 700;
    transition: 1s;
}
button:hover{
    background-color: rgb(211, 150, 37);
}
.dark{
    background:  rgb(20, 20, 20);
}
@media only screen and (max-width: 998px){
    h2{
        font-size: 1.3rem;
    }
    p{
        font-size: 0.875rem;
    }
    button{
        padding: 10px;
        background-color: rgb(227, 174, 75);
        color: white;
        font-weight: 700;
        transition: 1s;
    }
}