.header{
    background: url("../../public/images/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
}
.motioned{
    position: relative;
    height: inherit;
}
.motioned .headerContents{
    position: absolute;
    top: 5%;
    left: 0;
    /*transform: translate(-50%, -50%);*/
    width: 250px;
    height: 250px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid rgb(189, 189, 189);
    transition: 1s;
    animation-name: animatedCircle;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    cursor: pointer;
}
.motioned .headerContents{
    width: 50%;
    height: auto;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
} 
.motioned img{
    position: absolute;
    right: 5%;
    top: 5%;
    height: 78%;
    border-radius: 10px 0;
    transition: 1s;
    animation-name: image;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.motioned img:hover{
    scale: 0.9;
    cursor: pointer;
}
.motioned .h5{
    position: absolute;
    top: 73%;
    left: 7%;
    color: white;
    background: black;
    padding: 10px;
    font-size: 0.875rem;
}
@keyframes image{
    0%{
        filter: grayscale(0);
        transform: rotate(1deg);
    }
    50%{
        transform: rotate(-1deg);
    }
    100%{
        filter: grayscale(100%);
        transform: rotate(1deg);
    }
}
.motioned .headerContents a{
    text-decoration: underline;
    color: white;
}
.motioned .headerContents p{
    width: 60%;
    margin: auto;
    padding: 20px 5px;
}


@keyframes animatedCircle {
    0%{
        margin-top: 2.5%;
    }
    50%{
        margin-top: 4.5%;
    }
    100%{
        margin-top: 2.5%;
    }
}

.slidderImages{
    position: absolute;
    z-index: 2;
    width: 40%;
    right: 5%;
    top: 10%;
    height: 65%;
    transition: 1s;
    animation-name: none;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    border-radius: 20px 0;
    overflow: hidden;
}
.scrollButton{
    position: fixed;
    bottom: 5%;
    right: 2.5%;
}

@media only screen and (max-width: 998px){
    .header{
        height: auto;
    }
    .motioned{
        padding: 40px 0;
    }
    .motioned .headerContents{
        position: static;
        padding: 10px;
        width: 95%;
        margin: auto;
        height: auto;
        animation-name: unset;
    }
    .motioned .headerContents p{
        width: 90%;
        padding: 20px 5px;
        margin: auto;
    }
    .motioned h2{
        padding: 20px 10px 0 10px;
    }
    .motioned img{
        position:static;
        height: auto;
        width: 95%;
        display: block;
        text-align: center;
        margin: 5% auto;
        border-radius: 10px 0;
        transition: 1s;
        animation-name: none;
    }
    .motioned .h5{
        position: absolute;
        top: 65%;
        left: 7%;
        /*max-width: 85%;*/
        right: 7%;
        color: white;
        background: black;
        padding: 10px;
        font-size: 0.875rem;
    }
    .slidderImages{
        position: relative;
        right: 0;
        top: 20px;
        height: 250px;
        transition: 1s;
        overflow: hidden;
        width: 100%;
        margin: auto;
        z-index: 0;
    }
    .slidderImages img{
        height: 100px !important;
    }
}