.testimonials{
    padding: 2.5% 10%;
}
.testimonials .one{
    text-align: center;
    width: 60%;
    margin: auto;
    padding: 20px 0;
}
.testimonials .tale{
    border: 1px solid orange;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.testimonials .tale h4{
    padding: 20px 0;
    font-size: 1.2rem;
    color: orange;
}
.testimonials .tale div:first-child{
    width: 35%;
}
.testimonials .tale div img{
    width: 60%;
}
.testimonials .tale i{
    display: block;
    color: orange;
    font-size: 2rem;
    padding: 10px 0;
}